import { render, staticRenderFns } from "./FMCard.vue?vue&type=template&id=71ad298f&scoped=true&"
import script from "./FMCard.vue?vue&type=script&lang=js&"
export * from "./FMCard.vue?vue&type=script&lang=js&"
import style0 from "./FMCard.vue?vue&type=style&index=0&id=71ad298f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71ad298f",
  null
  
)

export default component.exports